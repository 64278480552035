<template>
    <b-card title="Add Employee">
        <div>
            <form-wizard color="#7367F0" :title="null" :subtitle="null"  finish-button-text="Submit"
                back-button-text="Previous" class="mb-3" @on-complete="formSubmitted">

                <!-- General details tab -->
                <tab-content title="Personal Details" :before-change="validationFormPersonalDetail">
                    <validation-observer ref="personalDetail" tag="form">
                        <b-row>
                            <b-col cols="12" class="mb-2">
                                <h5 class="mb-0">
                                    Personal Details
                                </h5>
                                <small class="text-muted">
                                    Enter Your Personal Details.
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <b-form-group label="Mobile No *">
                                    <validation-provider #default="{ errors }" name="Mobile No"
                                        rules="required|regex:^[6789]\d{9}$">
                                        <b-row>
                                            <b-col md="10">
                                                <b-form-input v-model="mobileNo"
                                                    :state="errors.length > 0 ? false : null"
                                                    placeholder="Please Enter Mobile No" />
                                            </b-col>
                                            <b-col md="2">
                                                <b-button @click="checkExistingEmoloyee" block>Validate</b-button>
                                            </b-col>
                                        </b-row>
                                    </validation-provider>


                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row v-if="validateNo">
                            <b-col md="3">
                                <b-form-group label="Customer Prefix">
                                    <v-select v-model="customerPrefix" :options="customerPrefixOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Customer Name *">
                                    <validation-provider #default="{ errors }" name="Customer Name" rules="required">
                                        <b-form-input v-model="customerName" :state="errors.length > 0 ? false : null"
                                            placeholder="Please Enter Customer Name" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Email">
                                    <b-form-input v-model="emailId" placeholder="Please Enter Email" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Address">
                                    <b-form-textarea v-model="address" rows="2" placeholder="Please Enter Address" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Area Location">
                                    <b-form-textarea v-model="areaLocation" rows="2"
                                        placeholder="Please Enter Area Location" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="State">
                                    <b-form-input v-model="state" placeholder="Please Enter State" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="PinCode">
                                    <b-form-input v-model="pinCode" placeholder="Please Enter Pin Code" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Customer Preference">
                                    <v-select v-model="customerPreference" label="name" placeholder="Select Preference"
                                        :options="customerPreferenceOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Default Discount (%)">
                                    <b-form-input v-model="defaultDiscountPercentage"
                                        placeholder="Please Enter Discount (%)" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Communication Preference">
                                    <v-select v-model="communicationPreference" label="name"
                                        placeholder="Select Communication Preference"
                                        :options="communicationPreferenceOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Customer GSTIN">
                                    <b-form-input v-model="gstin" placeholder="Please Enter Customer GSTIN" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Membership Id">
                                    <b-form-input v-model="membershipId" placeholder="Please Enter Membership Id" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Barcode">
                                    <b-form-input v-model="barcode" placeholder="Please Enter Barcode" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Birthday">
                                    <flat-pickr v-model="birthday" class="form-control" name="date"
                                        placeholder="Please Enter Birthday" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Anniversary Date">
                                    <flat-pickr v-model="anniversary" class="form-control" name="date"
                                        placeholder="Please Enter Anniversary Date" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Remark">
                                    <b-form-input v-model="remarks" placeholder="Please Enter Remark" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Home Delivery">
                                    <v-select v-model="homeDelivery" label="name" placeholder="Select Home Delivery"
                                        :options="homeDeliveryOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Marketing Source">
                                    <b-form-input v-model="marketingSource"
                                        placeholder="Please Enter Marketing Source" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Alternative Phone">
                                    <b-form-input v-model="alternetPhone" placeholder="Please Enter Phone" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Facebook ID">
                                    <b-form-input v-model="facebookId" placeholder="Please Enter Facebook ID" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Registration Source">
                                    <v-select v-model="registrationSource" label="name"
                                        placeholder="Enter Registration Source" :options="registrationSourceOption" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Message on Search Customer">
                                    <b-form-textarea v-model="messageOnSearchCustomer" rows="2"
                                        placeholder="Please Enter Message on Search Customer" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Message On Garment Delivery">
                                    <b-form-textarea v-model="messageOnGarmentDelivery" rows="2"
                                        placeholder="Please Enter Message On Garment Delivery" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>

                <!-- address  -->
                <tab-content title="Employee Details" :before-change="validationFormEmployeeDetail">
                    <validation-observer ref="employeeDetail" tag="form">
                        <b-row>
                            <b-col cols="12" class="mb-2">
                                <h5 class="mb-0">
                                    Employee Details
                                </h5>
                                <small class="text-muted">Enter Your Employee Details.</small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md=3>
                                <!-- <b-form-group label="Store Name">
                                    <v-select :options="storeNameOptions" v-model="storeName" label="storeName"
                                        placeholder="---Select Store---"></v-select>
                                </b-form-group> -->
                                <validation-provider #default="{ errors }" name="Store Name" rules="required">
                                    <b-form-group label="Store Name">
                                        <v-select :options="storeNameOptions" v-model="storeName"
                                            :state="errors.length > 0 ? false : null" label="storeName"
                                            placeholder="---Select Store---"></v-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>

                                </validation-provider>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Bank AC Detail">
                                    <b-form-input v-model="bankDetail"
                                        placeholder="Enter Bank AC Detial"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="PAN No">
                                    <b-form-input v-model="panDetail" placeholder="Enter PAN Detail"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Aadhar No">
                                    <b-form-input v-model="adharDetail" placeholder="Enter Aadhar No"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <validation-provider #default="{ errors }" name="Emergency Contact No"
                                    rules="required|regex:^[6789]\d{9}$">
                                    <b-form-group label="Emergency Contact No">
                                        <b-form-input v-model="emergencyContactNo"
                                            :state="errors.length > 0 ? false : null"
                                            placeholder="Please Enter Emergency Contact No" />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Barcode Id">
                                    <b-form-input v-model="barcodeId" placeholder="Enter Barcode Id"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Salary">
                                    <b-form-input v-model="salary" placeholder="Enter Salary"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Qualification">
                                    <b-form-input v-model="qualification"
                                        placeholder="Enter Qualification"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Joining Date">
                                    <flat-pickr v-model="joiningDate" class="form-control" name="date"
                                        placeholder="Please Enter Joining Date" />
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Job Type">
                                    <b-form-input v-model="jobType" placeholder="Enter Job Type"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Department">
                                    <b-form-input v-model="department" placeholder="Enter Department"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Experience Details">
                                    <b-form-input v-model="experienceDetails" placeholder="Enter Experience Details"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Leaving Reason">
                                    <b-form-input v-model="leavingReason"
                                        placeholder="Enter Leaving Reason"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Leaving Date">
                                    <flat-pickr v-model="leavingDate" class="form-control" name="date"
                                        placeholder="Please Enter Leaving Date" />
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Blood Group">
                                    <b-form-input v-model="bloodGroup" placeholder="Enter Blood Group"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Service Type">
                                    <b-form-input v-model="serviceType" placeholder="Enter Service Type"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group label="Remarks">
                                    <b-form-input v-model="employeeRemarks" placeholder="Enter Remarks"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md=3>
                                <b-form-group>
                                    <b-form-checkbox v-model="isActive">IsActive</b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>
            </form-wizard>

        </div>
    </b-card>
</template>
  
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    BRow,
    BCol, BCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback, BFormCheckboxGroup, BFormTextarea, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from '../../forms/form-wizard/code'
import flatPickr from 'vue-flatpickr-component'
import api from '@/store/api'
import util from '@/store/utils'

export default {
    components: {
        ValidationProvider, BCard, BFormTextarea,
        ValidationObserver,
        FormWizard,
        TabContent, BFormCheckbox,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback, BFormCheckboxGroup,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent, flatPickr, BButton
    },
    data() {
        return {
            validateNo: false,
            id: 0,
            customerPrefixOption: [],
            customerPrefix: 'Mr',
            customerName: '',
            mobileNo: '',
            emailId: '',
            address: '',
            areaLocation: '',
            state: '',
            pinCode: '',
            customerPreferenceOption: [],
            customerPreference: null,
            defaultDiscountPercentage: 0,
            communicationPreferenceOption: util.communicationPreferenceOptions,
            communicationPreference: null,
            gstin: '',
            membershipId: '',
            barcode: '',
            birthday: '',
            anniversary: '',
            remarks: '',
            homeDeliveryOption: ['Yes', 'No'],
            homeDelivery: '',
            enabelHomeDelevery: false,
            marketingSource: '',
            alternetPhone: '',
            facebookId: '',
            registrationSourceOption: [],
            registrationSource: null,
            messageOnSearchCustomer: '',
            messageOnGarmentDelivery: '',


            employeeId: 0,
            storeNameOptions: [],
            storeName: '',
            bankDetail: '',
            panDetail: '',
            adharDetail: '',
            emergencyContactNo: '',
            barcodeId: '',
            salary: '',
            qualification: '',
            joiningDate: '',
            jobType: '',
            department: '',
            experienceDetails: '',
            leavingReason: '',
            leavingDate: '',
            bloodGroup: '',
            serviceType: '',
            employeeRemarks: '',
            isActive: false,


            
          

            codeIcon,
            // userRoleOptions: [],
            // userRole: [],

        }
    },
    methods: {
        // getUserRole() {
        //     let self = this;
        //     var axios = require('axios');
        //     var data = '';
        //     axios(api.getApi('get', '/users/getAllRoles', data))
        //         .then(function (response) {
        //             //console.log(JSON.stringify(response.data));
        //             self.userRoleOptions = response.data;
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
        getNamePrefixList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getNamePrefixList', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPrefixOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getRegistrationSource() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getRegistrationSourceList', data))
                .then(function (response) {
                    let i=0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.registrationSourceOption.push({'id':i,'name':response.data[index]})
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getCustomerPreferences() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/customerPreferences', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPreferenceOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // getCommunicationPreference() {
        //     let self = this;

        //     var axios = require('axios');
        //     var data = '';
        //     axios(api.getApi('get', '/masters/communicationPreferences', data))
        //         .then(function (response) {
        //             //console.log(JSON.stringify(response.data));

        //             self.communicationPreferenceOption = response.data;
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
        checkExistingEmoloyee() {
            if (this.mobileNo.length == 10) {
                let self = this;
                var axios = require('axios');
                var data = '';

                axios(api.getApi('get', '/customers/filter/' + 'mobileNo' + ',' + self.mobileNo, data))
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        if (response.data != '') {

                            self.getUserDetails(response.data[0].id);
                        } else {
                            self.validateNo = true;
                            self.id = 0;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                this.validateNo = false;
                this.$swal({
                    title: '',
                    text: "Please Enter 10 Digits Mobile No.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        getUserDetails(userId) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/customers/' + userId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    
                    self.id = response.data.id;
                    self.customerPrefix = response.data.namePrefix;
                    self.customerName = response.data.name;
                    self.emailId = response.data.emailId;
                    self.address = response.data.address;
                    self.areaLocation = response.data.areaLocation;
                    self.state = response.data.state;
                    self.pinCode = response.data.pinCode;
                    if (response.data.customerPreference == null) {
                        self.customerPreference = '';
                    } else {
                        self.customerPreference = response.data.customerPreference;
                    }
                    self.defaultDiscountPercentage = response.data.defaultDiscountPercentage;
                    if (response.data.communicationPrefrence == null) {
                        self.communicationPreference = null;
                    } else {
                        self.communicationPreference = response.data.communicationPrefrence;
                    }
                    self.gstin = response.data.gstin;
                    self.membershipId = response.data.membershipId;
                    self.barcode = response.data.barcode;
                    self.birthday = response.data.birthday;
                    self.anniversary = response.data.anniversary;
                    self.remarks = response.data.remarks;
                    if (response.data.enabelHomeDelevery == true) {
                        self.homeDelivery = 'Yes';
                    } else {
                        self.homeDelivery = 'No';
                    }
                    self.marketingSource = response.data.marketingSource;
                    self.alternetPhone = response.data.alternetPhone;
                    self.facebookId = response.data.facebookId;
                    if (response.data.registrationSource > 0) {
                        const object = self.registrationSourceOption.find(obj => obj.id == response.data.registrationSource);
                        self.registrationSource = { 'id': object.id, 'name': object.name };
                    }
                    if (response.data.registrationSource == 0) {
                        self.registrationSource = 0;
                    }
                    self.messageOnSearchCustomer = response.data.msgOnSearchCustomer;
                    self.messageOnGarmentDelivery = response.data.msgOnGarmentDelevery;

                    //Get Employee Detail
                    self.getEmployeeDetail(response.data.id);

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getEmployeeDetail(userId) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getEmployeeByUserId/' + userId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.employeeId = response.data.id;
                        
                        self.$swal({
                            // title: 'Are you sure?',
                            text: "Employee Already Registered!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Edit Employee!',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                                cancelButton: 'btn btn-outline-danger ml-1',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if (result.value) {
                                self.$router.push({ name: 'editEmployee',params: { id: response.data.id }})
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    self.validateNo = true;
                });
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        formSubmitted() {
            // alert(this.id)
            let self = this;
            if (self.homeDelivery == 'Yes') {
                self.enabelHomeDelevery = true;
            } else if (self.homeDelivery == 'No') {
                self.enabelHomeDelevery = false;
            } else {
                self.enabelHomeDelevery = false
            }

            if (self.communicationPreference == null) {
                self.communicationPreference = null
            } else {
                self.communicationPreference = { 'id': self.communicationPreference.id, 'name': self.communicationPreference.name };
            }

            if (self.customerPreference == null) {
                self.customerPreference = null
            } else {
                self.customerPreference = { 'id': self.customerPreference.id, 'name': self.customerPreference.name };
            }

            if (self.registrationSource == null) {
                self.registrationSource = 0
            } else {
                self.registrationSource = self.registrationSource.id;
            }

            var axios = require('axios');
            var data = JSON.stringify(
                {
                    user: {
                        "id": self.id,
                        "namePrefix": self.customerPrefix,
                        "name": self.customerName,
                        "mobileNo": self.mobileNo,
                        "emailId": self.emailId,
                        "address": self.address,
                        "areaLocation": self.areaLocation,
                        "state": self.state,
                        "pinCode": self.pinCode,
                        "customerPreference": self.customerPreference,
                        "defaultDiscountPercentage": self.defaultDiscountPercentage,
                        "communicationPrefrence": self.communicationPreference,
                        "gstin": self.gstin,
                        "membershipId": self.membershipId,
                        "barcode": self.barcode,
                        "birthday": self.birthday,
                        "anniversary": self.anniversary,
                        "remarks": self.remarks,
                        "enabelHomeDelevery": self.enabelHomeDelevery,
                        "marketingSource": self.marketingSource,
                        "alternetPhone": self.alternetPhone,
                        "facebookId": self.facebookId,
                        "registrationSource": self.registrationSource,
                        "msgOnSearchCustomer": self.messageOnSearchCustomer,
                        "msgOnGarmentDelevery": self.messageOnGarmentDelivery,
                    },
                    store: {
                        "id": self.storeName.id
                    },
                    "bankDetail": self.bankDetail,
                    "panDetail": self.panDetail,
                    "adharDetail": self.adharDetail,
                    "emergencyContactNo": self.emergencyContactNo,
                    "barcodeId": self.barcodeId,
                    "salary": self.salary,
                    "qualification": self.qualification,
                    "joinDate": self.joinDate,
                    "jobType": self.jobType,
                    "department": self.department,
                    "experienceDetails": self.experienceDetails,
                    "leavingReason": self.leavingReason,
                    "leavingDate": self.leavingDate,
                    "booldGroup": self.bloodGroup,
                    "serviceType": self.serviceType,
                    "isActive": self.isActive,
                    "remarks": self.employeeRemarks,

                })
            // alert(data);
            axios(api.getApi('Post', '/employees', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Employee Created Successfully',
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    })
                    self.$router.push({ name: 'editEmployee', params: { id: response.data.id } })
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        validationFormPersonalDetail() {
            return new Promise((resolve, reject) => {
                this.$refs.personalDetail.validate().then(success => {
                    if (success) {
                        if (this.validateNo == true) {
                            resolve(true)
                        } else {
                            reject()
                            this.$swal({
                                title: '',
                                text: "Please Validate Mobile No!",
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        }
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormEmployeeDetail() {
            return new Promise((resolve, reject) => {
                this.$refs.employeeDetail.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        // validationFormUserRole() {
        //     return new Promise((resolve, reject) => {
        //         this.$refs.userRole.validate().then(success => {
        //             if (success) {
        //                 resolve(true)
        //             } else {
        //                 reject()
        //             }
        //         })
        //     })
        // },
    },
    created() {
        // this.getUserRole();
        this.getRegistrationSource();
        this.getCustomerPreferences();
        // this.getCommunicationPreference();
        this.getStoreName();
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
<style>
.demo-inline-spacing {
    display: block;
}
</style>
  